import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";


const BulletinPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;
  const files = data.markdownRemark.frontmatter.bulletinFile
  console.log(files)
  return (
    <Layout>
      <div className="section-container">

        <section className="section">
          <h1>{frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </section>
        <section className="section" style={{
          marginTop: '-24px'
        }}>
          <ul>
            {
              files.map((f, index) => (
                <li key={index}>
                  <a className="BIPLink" href={f.file.publicURL} target="_blank" rel="noreferer">{f.name}</a>
                </li>
              ))
            }
          </ul>
        </section>
      </div>

    </Layout>
  );
}

export default BulletinPage;

export const Head = () => (
  <SEO
    title="Biuletyn"
    description="Biuletyn"
    path="/bulletin"
  />
)

export const query = graphql`
  query BulletinPage {
    markdownRemark(frontmatter: {templateKey: {eq: "bulletin-page"}}) {
      html
      frontmatter {
        bulletinFile {
          file {
            publicURL
          }
          name
        }
        title
      }
    }
  }
`
